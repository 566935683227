import moment from "moment-timezone";

export const formatDate = (date, timezone) => {
  const formattedDate = moment.unix(date._seconds).utcOffset(timezone);

  const today = moment().utcOffset(timezone).startOf("day");
  const tomorrow = moment().utcOffset(timezone).add(1, "day").startOf("day");

  if (formattedDate.isBefore(today, "day")) {
    return "Today";
  } else if (formattedDate.isSame(today, "day")) {
    return "Today";
  } else if (formattedDate.isSame(tomorrow, "day")) {
    return "Tomorrow";
  } else {
    return formattedDate.format("ddd, MMM D");
  }
};

export const StartDateRange = (startDate, timezone) => {
  // console.log({ timezone });
  const start = moment.unix(startDate._seconds).utcOffset(timezone, false);
  // console.log({ start });

  let startDay = start.format("ddd MMM D");

  if (start.isSame(moment().utcOffset(timezone).startOf("day"), "day")) {
    startDay = "Today";
  } else if (
    start.isSame(
      moment().utcOffset(timezone).add(1, "day").startOf("day"),
      "day"
    )
  ) {
    startDay = "Tomorrow";
  }
  return `${startDay}, ${start.format("h:mm A")}`;
};

export const EndDateRange = (endDate, timezone) => {
  const end = moment.unix(endDate._seconds).utcOffset(timezone);
  let endDay = end.format("ddd MMM D");

  if (end.isSame(moment().utcOffset(timezone).startOf("day"), "day")) {
    endDay = "Today";
  } else if (
    end.isSame(moment().utcOffset(timezone).add(1, "day").startOf("day"), "day")
  ) {
    endDay = "Tomorrow";
  }

  return `${endDay}, ${end.format("h:mm A")}`;
};

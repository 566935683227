import axios from "axios";
import config from "../config/config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

const firebaseInstance = axios.create({
  baseURL: config.FIREBASE_WS_BASE_URL,
  headers: {
    Authorization: `Basic ${config.FIREBASE_BASIC_AUTH_TOKEN}`,
    "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});

const firebaseSeatingInstance = axios.create({
  baseURL: config.FIREBASE_WS_BASE_URL,
  headers: {
    Authorization: `Basic ${config.FIREBASE_BASIC_Seat_AUTH_TOKEN}`,
    "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});

const firebaseInstanceBearer = axios.create({
  baseURL: config.FIREBASE_WS_BASE_URL,
  headers: {
    Authorization: `Bearer ${config.FIREBASE_BEARER_AUTH_TOKEN}`,
  },
});

const tokenInstance = axios.create({
  baseURL: config.TOKEN_API_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers["x-access-token"] = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

export {
  instance,
  firebaseInstance,
  firebaseSeatingInstance,
  firebaseInstanceBearer,
  tokenInstance,
};

import React, { useEffect, useState } from "react";
import styles from "./Search.module.css";
import { StartDateRange } from "@utils/dateFormatter";
import { useRouter } from "next/navigation";

const Events = (props) => {
  const time = () => {
    return StartDateRange(props.start, props.timezone.value);
  };
  const router = useRouter();
  return (
    <li
      onClick={() => {
        router.push(`/events/${props.eventID}`);
      }}
    >
      <img src={props.image} alt="img" />
      <span className={styles.total}>
        {props.name.length > 30
          ? `${props.name.substring(0, 30)} ...`
          : props.name}{" "}
        <br />
        <div className={styles.org}>
          <span className={styles.orgName}>{props.phone} |</span>
          <span> {time()}</span>
        </div>
      </span>
      {/* <span className={styles.phone}>{props.phone}</span> */}
      <div className={styles.brick}></div>
    </li>
  );
};
const NewSearchBar = ({ allEvents = [] }) => {
  const [filtered, setFiltered] = useState([]);
  useEffect(() => {
    allEvents = allEvents.filter(
      (event) => event.eventId !== "eHaECJSmHrBAs7ht3WoR"
    );
  }, []);
  const handleFiltered = (name) => {
    if (name.length === 0) {
      setFiltered([]);
      return;
    }
    setFiltered(
      allEvents.filter(
        (event) =>
          event.eventId !== "eHaECJSmHrBAs7ht3WoR" &&
          event.name.toLowerCase().includes(name.toLowerCase())
      )
    );
  };
  return (
    <>
      <div className={styles.search}>
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => handleFiltered(e.target.value)}
        />
      </div>

      {filtered.length > 0 && (
        <div className={styles.list}>
          <ul>
            {filtered.map((el) => {
              // Filter out the event with eventId "eHaECJSmHrBAs7ht3WoR"
              if (el.eventID === "eHaECJSmHrBAs7ht3WoR") {
                return null; // Skip rendering this event
              }

              return (
                <Events
                  key={el.eventID}
                  eventID={el.eventID}
                  name={el.name}
                  image={el.thumbnailUrl}
                  phone={el.orgSnippet.orgName}
                  start={el.startDate}
                  timezone={el.timezoneMap}
                />
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
export default NewSearchBar;

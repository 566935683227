import {
  firebaseInstance,
  firebaseInstanceBearer,
  firebaseSeatingInstance,
} from "./api";
import config from "@config/config";

export const getAllEvents = async () => {
  try {
    //?eventID
    const response = await firebaseInstance.get(
      "app/get-events?source=tickit.co"
    );
    return response.data;
  } catch (error) {
    // re-throw the error so react-query can catch it
    throw error;
  }
};

export const GenerateInvoice = async function generateInvoice(eventID, orgID) {
  try {
    const response = await axios.post(
      signInURL14,
      {
        eventID: eventID,
        orgID: orgID,
      },
      {
        headers: { Authorization: `Bearer ${tokenStr}` },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GenerateReceipt = async function generateReceipt(eventID, orgID) {
  try {
    const response = await axios.post(
      signInURL15,
      {
        eventID: eventID,
        orgID: orgID,
      },
      {
        headers: { Authorization: `Bearer ${tokenStr}` },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getEventsLocation = async () => {
  try {
    //?eventID
    const response = await firebaseInstance.get("app/get-events-locations");
    // console.log(response);
    return response.data;
  } catch (error) {
    // re-throw the error so react-query can catch it
    throw error;
  }
};

export const getEventFetch = async (eventID) => {
  try {
    const response = await fetch(
      `${config.FIREBASE_WS_BASE_URL}/app/get-event-byID`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${config.FIREBASE_BASIC_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          eventID,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch event data");
    }
  } catch (error) {
    // re-throw the error so react-query can catch it
    throw error;
  }
};

export const getEvent = async (eventID) => {
  try {
    const response = await firebaseInstance.post(`app/get-event-byID`, {
      eventID,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // console.log("Error response:", error.response);
    } else if (error.request) {
      // console.log("Error request:", error.request);
    } else if (error.message) {
      // console.log("Error message:", error.message);
    }
    // re-throw the error so react-query can catch it
    return error.response.data.message;
    throw new Error(error.response.data.message);
  }
};

export const getEvents = async (type, value) => {
  try {
    const response = await firebaseInstance.get(
      `app/get-events?source=tickit.co&${type}=${value}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(
      error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred while fetching events"
    );
  }
};

export const getArtistByID = async (artistID, userID) => {
  try {
    const response = await firebaseInstance.get(
      `app/get-artists-byID?artistID=${artistID}&userID=${userID}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred while fetching events"
    );
  }
};
export const getOrgs = async (orgID) => {
  try {
    const response = await firebaseInstance.post(`app/get-org-byID`, { orgID });
    return response.data;
  } catch (error) {
    // console.log(
    //   error?.response?.data?.message ||
    //     error?.message ||
    //     "Unknown error occurred while fetching events"
    // );
    throw new Error(
      error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred while fetching events"
    );
  }
};

export const getEventsByCountry = async (country) => {
  try {
    const response = await firebaseInstance.post(`app/get-events-by-country`, {
      country,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // console.log("Error response:", error.response);
    } else if (error.request) {
      // console.log("Error request:", error.request);
    } else if (error.message) {
      // console.log("Error message:", error.message);
    }
    // re-throw the error so react-query can catch it
    throw error;
  }
};

export const getLocations = async () => {
  return await firebaseInstance.get(`app/get-locations`);
};

export const getBook = async (country) => {
  try {
    const response = await firebaseInstance.post(`app/get-book-events`, {
      country,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // console.log("Error response:", error.response);
    } else if (error.request) {
      // console.log("Error request:", error.request);
    } else if (error.message) {
      // console.log("Error message:", error.message);
    }
    // re-throw the error so react-query can catch it
    throw error;
  }
};

export const getOrgsByCountry = async (country) =>
  await firebaseInstance.post(`app/get-orgs-by-country`, {
    country,
  });

export const checkHolders = async (eventID, holders, eventEntryID) => {
  return await firebaseInstance.post(`app/check-holders`, {
    eventID,
    holders,
    eventEntryID,
  });
};

export const createGuestlistRequest = async (
  listID,
  eventID,
  primaryGuest,
  expectedGuests,
  socialLinks
) => {
  return await firebaseInstance.post(`app/create-guestlist-request`, {
    listID,
    eventID,
    primaryGuest,
    expectedGuests,
    socialLinks,
  });
};

export const followArtist = async (
  userID,
  artistID,
  follow,
  sendNotification,
  sendEmail
) => {
  return await firebaseInstance.post(`app/follow-artist`, {
    userID,
    artistID,
    follow,
    sendNotification,
    sendEmail,
  });
};

export const getSeatingMapData = async (seatingID) => {
  try {
    const response = await firebaseSeatingInstance.get(`/seating/${seatingID}`);

    return response;
  } catch (error) {
    if (error.response) {
      // console.log("Error response:", error.response);
    } else if (error.request) {
      // console.log("Error request:", error.request);
    } else if (error.message) {
      // console.log("Error message:", error.message);
    }
    // re-throw the error so react-query can catch it
    throw error;
  }
};

export const getQrCode = async (receiptID, scanCodeID, token) => {
  try {
    // Constructing the URL without appending a trailing slash if scanCodeID is undefined
    const url = `app/qrcodes/${receiptID}${
      scanCodeID ? `/${scanCodeID}` : ""
    }?token=${token}`;
    console.log(url);

    const response = await firebaseInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(
      error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred while fetching events"
    );
  }
};

import { useState } from "react";
import { Listbox } from "@headlessui/react";
import Cookies from "js-cookie";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useRouter } from "next/navigation";
import styles from "./LoginDropdown.module.css";

const LoginDropdown = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const options = [
    { name: "My Events", action: () => router.push("/my-events") },
    {
      name: "Profile",
      action: () => {
        router.push("/profile");
      },
    },
    {
      name: "Logout",
      action: () => {
        Cookies.remove("user");
        Cookies.remove("tokens");
        window.location.reload();
      },
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  return (
    <>
      <Listbox
        value={selectedOption}
        onChange={(option) => {
          setSelectedOption(option);
          option.action();
          setIsOpen(false);
        }}
      >
        <Listbox.Button
          onClick={() => setIsOpen(!isOpen)}
          className={styles.logedIn}
        >
          <span>
            {user && user.fname && user.fname.substring(0, 1)}
            {user && user.lastName && user.lastName.substring(0, 1)}
          </span>
        </Listbox.Button>

        <Listbox.Options className={isOpen ? styles.isOpen : styles.isAbso}>
          {options.map((option, index) => (
            <Listbox.Option key={index} value={option} className={styles.li}>
              {option.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </>
  );
};

export default LoginDropdown;
